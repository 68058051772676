import  React, { useState } from 'react';
import useResizeAware from "react-resize-aware";
import { AutoComplete } from 'antd';
import DropDown from "./Dropdown"
import '../stylesheets/searchBar.css';
const fuzzysort = require('fuzzysort');

// ant component here https://ant.design/components/auto-complete/

// create the function to be read by component	
const Complete = (props) => {
  //value is what's on the searchBar and options are part of what 'drops down'
  const [value, setValue] = useState('');
  const [options, setOptions] = useState([]);

  // fuzzysort documentation: https://github.com/farzher/fuzzysort
  const bestMatchName = (input, names) => {
    //options for our string matching
    const fuzzyOptions = {
      allowTypo: true,
      limit: 3 //only use the best 3 options
    }
    //perform the fuzzy string matching
    const results = fuzzysort.go(input, names, fuzzyOptions)
    let returnNames = []; //what we'll return
    // options expects [{'value' : nodeName}. {'value' : nodeName}]
    for (const property in results) {
        returnNames.push({'value' : results[property]['target']})
    };
    return returnNames;
  }

  // TODO: make this the algorithm to iterate through nodeNames
  const onSearch = (searchText) => {
    //all of the potential node names
    let names = props.names;

    setOptions(
      !searchText ? [] : bestMatchName(searchText, names),
    );
  };

  const onSelect = (data) => {
    props.handleNodeChange(data);
  };

  //change the value in the searchBar
  const onChange = (data) => {
    setValue(data);
  };
  
  const [resizeListener, sizes] = useResizeAware();
  let style = {
    position: "relative",
    height: "100%",
    width: sizes.width * 0.4,
    paddingRight: "10px",
  }
  return (
    <div>
      {resizeListener}
      <AutoComplete
        style={style}
        onChange={onChange}
        onSearch={onSearch}
        onSelect={onSelect}
        options={options}
        placeholder={'Select a dataset'}
        value={value}
      />
    </div>
  );		  
}	

// create the react component
const SearchBar = (props) => {
  const [resizeListener, sizes] = useResizeAware();
  let drop_style = {
    position: "relative",
    height: "45%",
    width: "100%",
    paddingRight: "10px",
    marginTop: "20px",
  }
  return(
    <div className='SameRow' style={{ width: "60%", }}>
      <div className='SearchBar'>
        <Complete handleNodeChange={props.handleNodeChange} names={props.nodeNames}/>
      </div>
      <DropDown 
        categories={props.categories.sort()}
        category={props.category}
        changeCategory={props.changeCategory}
        style={drop_style}/>
    </div>
  );
}

export default SearchBar;
