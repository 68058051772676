import { Menu, Dropdown, Button } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import React from "react";

// ant design documentation: https://ant.design/components/dropdown/

// iterates through a given list and adds them to the dropdown's items 
const BuildMenu = (props) => {
    // pass in names and alphabetize
    const categories = props.categories.sort() 
    // render our Menu Items
    let menuItems = [<Menu.Item key="all"><p>All</p></Menu.Item>]
    menuItems = menuItems.concat(categories.map((category) =>
        <Menu.Item key={category}><p>{category}</p></Menu.Item>
    ));
    // function to handle clicks
    const handleClick = e => {
        if(e.key == 'all') {
            e.key = "";
        }
        props.changeCategory(e.key)
    }
    
    return (
        <Menu onClick={handleClick} >{menuItems}</Menu>
    );
}



// create the component
const DropDown = (props) => {
    const menu = <BuildMenu categories={props.categories} changeCategory={props.changeCategory}/>
    const category_text = (props.category ? props.category : "Category");
    return (
        <Dropdown overlay={menu} placement="bottomCenter" arrow>
            <Button style={props.style}>{category_text} <DownOutlined /></Button>
        </Dropdown>
    );
}

export default DropDown;