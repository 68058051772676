import React, { useEffect, useRef } from "react";
import useResizeAware from "react-resize-aware";
import PropTypes from "prop-types";
import Neovis from "neovis.js/dist/neovis.js";
const neo4j = require('neo4j-driver');

// connect to our db
const {REACT_APP_NEO4J_URI,  REACT_APP_NEO4J_USER, REACT_APP_NEO4J_PASSWORD} = process.env;
// connect to the db
const driver = neo4j.driver(
  REACT_APP_NEO4J_URI,
  neo4j.auth.basic(REACT_APP_NEO4J_USER, REACT_APP_NEO4J_PASSWORD),
  { encrypted: 'ENCRYPTION_ON'}
);

const NeoGraph = (props) => {
  const {
    category,
    width,
    handleNodeChange,
    height,
    containerId,
    backgroundColor,
    neo4jUri,
    neo4jUser,
    neo4jPassword,
    minScore,
    nodeName
  } = props;

  // shorten long names on graph
  const shorten_name = (node) => {
    let name = node.properties.Name
    return name.length > 15 ? name.substring(0, 15) + '...' : name
  }

  let query = `
  MATCH (n)-[r:SCORE]->(m)
  WHERE r.score >= ${minScore} AND n.Name = '${nodeName}'
  RETURN *`

  // update query when initial state changes
  if (category !== '') {
    query = `
    MATCH (n)-[r:SCORE]->(m)
    WHERE r.score >= ${minScore} 
    AND n.Name = '${nodeName}'
    AND m.category = '${category}'
    RETURN *
    `
  }
  
  const visRef = useRef();
  useEffect ( () => {
    const config = {
      container_id: visRef.current.id,
      server_url: neo4jUri,
      server_user: neo4jUser,
      server_password: neo4jPassword,
      encrypted: 'ENCRYPTION_ON',
      trust: 'TRUST_SYSTEM_CA_SIGNED_CERTIFICATES',
      labels: {
        "BaseDataset": {
          "caption": shorten_name,
          "community": "category_int",
          "title_properties": [
            "Name",
            "state_steward",
            "category"
        ]
        },
      },
      relationships: {
        "SCORE": {
          "caption": false,
          "thickness": "score",
        },
      },
      initial_cypher:  query
    };
    const vis = new Neovis(config);

    // register node click events
    // TODO: this only returns the 'id' of the node
    // there is chatter of expanding this, but no definite solution
    vis.registerOnEvent("completed", (e)=>{ 
      vis["_network"].on("click", (event)=>{
        let id = event.nodes[0] // id of node
        let query = `MATCH (n:BaseDataset)
        WHERE ID(n) = ${id}
        RETURN n.Name`
        // only change if a node is selected
        if (id) {
          // create our session
          const session = driver.session({ defaultAccessMode: neo4j.session.READ });
          session.run(query)
            .then((result) => {
              let newNodeName = result.records[0]._fields
              handleNodeChange(newNodeName)
            })
            .catch((error) => {
              console.log(error);
            });

        }
      });
  });

    vis.render();
  }, [category, handleNodeChange, neo4jUri, neo4jUser, neo4jPassword, minScore, nodeName, query]);

  return (
    <div
      id={containerId}
      ref={visRef}
      style={{
        width: `${width}px`,
        height: `${height}px`,
        backgroundColor: `${backgroundColor}`,
      }}
    />
  );
};

NeoGraph.defaultProps = {
  width: 600,
  height: 600,
  backgroundColor: "#d3d3d3",
};

NeoGraph.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  containerId: PropTypes.string.isRequired,
  neo4jUri: PropTypes.string.isRequired,
  neo4jUser: PropTypes.string.isRequired,
  neo4jPassword: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string,
};

const ResponsiveNeoGraph = (props) => {
  const [resizeListener, sizes] = useResizeAware();
  const min_side = 200;
  sizes.width = sizes.width > min_side ? sizes.width : min_side;
  sizes.height = document.documentElement.clientHeight > min_side ? document.documentElement.clientHeight : min_side;
  const neoGraphProps = { ...props, width: sizes.width, height: sizes.height - 125};
  
  return (
    <div style={{ position: "relative", width: "60%"}}>
      {resizeListener}
      <NeoGraph {...neoGraphProps} />
    </div>
  );
};

ResponsiveNeoGraph.defaultProps = {
  backgroundColor: "#eaeaea",
};

ResponsiveNeoGraph.propTypes = {
  containerId: PropTypes.string.isRequired,
  neo4jUri: PropTypes.string.isRequired,
  neo4jUser: PropTypes.string.isRequired,
  neo4jPassword: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string,
};

export { NeoGraph, ResponsiveNeoGraph };