import React, { useState } from "react";
import { ResponsiveNeoGraph } from "./NeoGraph";
// import DropDown from "./Dropdown"
import ScoreSlider from "./scoreSlider";
import SearchBar from "./SearchBar";
import MetadataBox from "./MetadataBox"
import '../stylesheets/App.css';
const neo4j = require('neo4j-driver');

const {REACT_APP_NEO4J_URI,  REACT_APP_NEO4J_USER, REACT_APP_NEO4J_PASSWORD} = process.env;

// bring in a variable for all node names
// connect to the db
const driver = neo4j.driver(
  REACT_APP_NEO4J_URI,
  neo4j.auth.basic(REACT_APP_NEO4J_USER, REACT_APP_NEO4J_PASSWORD),
  { encrypted: 'ENCRYPTION_ON'}
);

// a basic query to get all node names
const basicInfoQuery =
`
MATCH (n:BaseDataset)
RETURN n.Name, n.category
`;

// create our session
const session = driver.session({ defaultAccessMode: neo4j.session.READ });

// arrays to collect all node names, categories and tags
let nodeNames = [];
let categories = [];

// make the query
session.run(basicInfoQuery)
  .then((result) => {
      result.records.forEach((record) => {
        // append the values if they don't already exist
        if (!nodeNames.includes(record._fields[0])) {
          nodeNames.push(record._fields[0]) // these are the Node Names
        };

        if (!categories.includes(record._fields[1])) {
          categories.push(record._fields[1]) // these are the Node Names
        }; 
      });
      session.close();
  })
  .catch((error) => {
      console.log(error);
  });

const App = () => {
    // set state for base node name
    const [nodeName, setNodeName] = useState('Retail Reports by County in Colorado');
   
    // set state for minimum score for matching
    const [minScore, setMinScore] = useState(35); // score slider

    // set state for minimum score for matching
    const [category, setCategory] = useState(''); // score slider

    // handle state function to change the name
    function handleNodeChange(name) {
      setNodeName(name); 
    }

    // handle state function to change the minScore
    function handleMinScoreChange(score) {
      setMinScore(score);
    }

    function changeCategory(category) {
      setCategory(category);
    }

    let menu_options_style = {
      "marginLeft": "2.5%",
      "width": "97.5%"
    }

  // render our application
  return (
    <div style={{ fontFamily: "Quicksand" }}>
      <h1 className="TitleText" >Data Discovery Tool</h1>
      <div className='SameRow' style={menu_options_style}>
        <SearchBar
          categories={categories}
          handleNodeChange={handleNodeChange}
          category={category}
          nodeNames={nodeNames}
          changeCategory={changeCategory}
          style={{ width: "60%" }}
        />
        <ScoreSlider
          handleMinScoreChange={handleMinScoreChange}
          nodeName={nodeName}
          minScore={minScore}
          style={{ width: "37.5%" }}
        />
      </div>
      <div className='SameRow' style={menu_options_style}>
        <ResponsiveNeoGraph
          category={category}
          containerId={"id0"}
          handleNodeChange={handleNodeChange}
          minScore={minScore}
          neo4jUri={REACT_APP_NEO4J_URI}
          neo4jUser={REACT_APP_NEO4J_USER}
          neo4jPassword={REACT_APP_NEO4J_PASSWORD}
          nodeName={nodeName}
        />
        <MetadataBox 
          nodeName={nodeName}
          category={category}
          minScore={minScore}
        />
      </div>
    </div>
  );
};

export default App;