import React, { useEffect, useState } from "react";
import useResizeAware from "react-resize-aware";
import { Card } from 'antd';
const neo4j = require('neo4j-driver');

// db creds
const {REACT_APP_NEO4J_URI,  REACT_APP_NEO4J_USER, REACT_APP_NEO4J_PASSWORD} = process.env;
// connect to the db
const driver = neo4j.driver(
  REACT_APP_NEO4J_URI,
  neo4j.auth.basic(REACT_APP_NEO4J_USER, REACT_APP_NEO4J_PASSWORD),
  { encrypted: 'ENCRYPTION_ON'}
);

const gridStyle = {
  width: '40%',
  textAlign: 'center',
  padding: "8px",
};

// create the component
const MetadataBox = (props) => {
  // state to store the data for the box from our query
  const [dataSource, setDataSource] = useState([]);

  let where_category = (props.category ? `AND m.category = '${props.category}'` : "");
  const query = `
    MATCH (n)-[r:SCORE]->(m)
    WHERE r.score >= ${props.minScore} 
    AND n.Name = '${props.nodeName}'
    ${where_category}
    RETURN *
    ORDER BY r.score DESC
  `
  useEffect(() => {
    async function buildDataSource() {
      const session = driver.session({ defaultAccessMode: neo4j.session.READ });
      try {
      // make query
      const result = await session.readTransaction(tx =>
        tx.run(query)
      )
      // Need to parse the whole list of records
      let properties = result.records;
      properties.map(link => (
        link._fields[0].properties.url = 'https://data.colorado.gov/a/b/' + link._fields[0].properties.api_4x4
      ));
      if(properties[0]) {
        properties[0]._fields[1].properties.url = 'https://data.colorado.gov/a/b/' + properties[0]._fields[1].properties.api_4x4;
        setDataSource(properties);
      } else {
        // No datasets were returned in query, need to notify user!
        properties = dataSource.slice(0, 1);
        properties.push({
          "no_data": true, 
          "message": "No data with these selections. Try lowering the minimum score or changing the category filter."
        });
        setDataSource(properties);
      }
    } catch (error) {
      console.log(error);
    } finally {
      await session.close();
    }
  }
  buildDataSource();
  }, [query]);
  const [resizeListener, sizes] = useResizeAware();
  const min_side = 100;
  sizes.width = sizes.width > min_side ? sizes.width : min_side;
  sizes.height = document.documentElement.clientHeight > min_side ? document.documentElement.clientHeight - 125 : min_side;
  //Need to add fields, keywords, short_description, long_description, fields
  let root_card = "";
  if (dataSource && dataSource.length > 0) {
    root_card = <Card title={dataSource[0]._fields[1].properties.Name} key={dataSource[0]._fields[1].properties.api_4x4} extra=<a href={dataSource[0]._fields[1].properties.url} target="_blank">Data on CIM</a> style={{ width: "100%", "backgroundColor": "#eaeaea" }}>
      <Card.Grid hoverable={false} style={{ width: '20%', textAlign: 'center', padding: "8px", }}>
        <b>Central Record</b>
      </Card.Grid>
      <Card.Grid hoverable={false} style={gridStyle}>
        <b>Cateogory: </b>{dataSource[0]._fields[1].properties.category}
      </Card.Grid>
      <Card.Grid hoverable={false} style={gridStyle}>
        <b>State Steward: </b>{dataSource[0]._fields[1].properties.state_steward}
      </Card.Grid>

      <p style={{ "paddingRight": "5px", "paddingLeft": "5px" }}><b>Description: </b>{dataSource[0]._fields[1].properties.short_description}</p>
      <p style={{ "paddingRight": "5px", "paddingLeft": "5px" }}><b>Long Description: </b>{dataSource[0]._fields[1].properties.long_description}</p>
      <p style={{ "paddingRight": "5px", "paddingLeft": "5px" }}><b>Field Names: </b>{dataSource[0]._fields[1].properties.fields}</p>
      <p style={{ "paddingRight": "5px", "paddingLeft": "5px" }}><b>Keywords: </b>{dataSource[0]._fields[1].properties.keywords}</p>
    </Card>;
  }
  if (dataSource && dataSource[1] && dataSource[1].no_data) {
    return (
      <div style={{ position: "relative", left:'1.5%', width: "37.5%", height: sizes.height, overflow: "auto" }}>
        {resizeListener}
        {root_card}
        <p><b>{dataSource[1].message}</b></p>
      </div>
    );
  }
  return (
    <div style={{ position: "relative", left:'1.5%', width: "37.5%", height: sizes.height, overflow: "auto" }}>
        {resizeListener}
        {root_card}
        {dataSource.map(link => (
          <Card title={link._fields[0].properties.Name} key={link._fields[0].properties.api_4x4} extra=<a href={link._fields[0].properties.url} target="_blank">Data on CIM</a>style={{ width: "100%",  }}>
            <Card.Grid hoverable={false} style={{ width: '20%', textAlign: 'center', padding: "8px", }}>
              <b>Score: </b>{link._fields[2].properties.score}
            </Card.Grid>
            <Card.Grid hoverable={false} style={gridStyle}>
              <b>Cateogory: </b>{link._fields[0].properties.category}
            </Card.Grid>
            <Card.Grid hoverable={false} style={gridStyle}>
              <b>State Steward: </b>{link._fields[0].properties.state_steward}
            </Card.Grid>

            <p style={{ "paddingRight": "5px", "paddingLeft": "5px" }}><b>Description: </b>{link._fields[0].properties.short_description}</p>
            <p style={{ "paddingRight": "5px", "paddingLeft": "5px" }}><b>Long Description: </b>{link._fields[0].properties.long_description}</p>
            <p style={{ "paddingRight": "5px", "paddingLeft": "5px" }}><b>Field Names: </b>{link._fields[0].properties.fields}</p>
            <p style={{ "paddingRight": "5px", "paddingLeft": "5px" }}><b>Keywords: </b>{link._fields[0].properties.keywords}</p>
          </Card>
        ))}
    </div>
  );
}

export default MetadataBox;