import React, { useEffect, useState } from 'react';
import useResizeAware from "react-resize-aware";
import { Slider } from 'antd';
import '../stylesheets/scoreSlider.css'
const neo4j = require('neo4j-driver');

// db creds
const {REACT_APP_NEO4J_URI,  REACT_APP_NEO4J_USER, REACT_APP_NEO4J_PASSWORD} = process.env;

// bring in a variable for all node names
// connect to the db
const driver = neo4j.driver(
  REACT_APP_NEO4J_URI,
  neo4j.auth.basic(REACT_APP_NEO4J_USER, REACT_APP_NEO4J_PASSWORD),
  { encrypted: 'ENCRYPTION_ON'}
);

// create the component	
const ScoreSlider = (props) => {
  // set a default maximum score
  const [maxScore, setMax] = useState(50);
  // a query to get the highest score
  const getMaxScoreQuery =
  `
  MATCH (n)-[r:SCORE]->(m)
  WHERE n.Name = '${props.nodeName}'
  RETURN MAX(r.score)
  `;


  // we need to dynamically pull in the maxScore
  useEffect(() => {
    async function getMaxScore() {
      const session = driver.session({ defaultAccessMode: neo4j.session.READ });
      try {
        const result = await session.readTransaction(tx =>
          tx.run(getMaxScoreQuery)
        )
        // dynamically set the max Score
        const records = result.records;
        setMax(records[0]._fields[0]);

      } catch (error) {
        console.log(error)
        //close session
      } finally {
        await session.close();
      }
    }
    // call the function
    getMaxScore();
  }, [getMaxScoreQuery]);
  
  // set maximum and minimum slider values	
  const min = 0;
  const max = Math.floor(maxScore);
  // set values to maximum and minimum	
  const marks = {};
  marks[min] = min.toString();
  marks[max] = max.toString();
  
  //change the minimum score shown if it's greater than max
  if (props.minScore > maxScore) {
    props.handleMinScoreChange(Math.floor(maxScore * 0.75));
  }
  
  // dynamically change on window change
  const [resizeListener, sizes] = useResizeAware();
  // style the score slider
  let style = {
    height: "70px",
    width: sizes.width * 0.365
  }
  return (
    <div className='scoreSlider' style={style}>
      {resizeListener}
      <h3>Move the slider to adjust the minimum related score</h3>
      <Slider
      onAfterChange={props.handleMinScoreChange}
      defaultValue={props.minScore}
      min={min}	
      max={max}	
      marks={marks}	
      // tooltipVisible={true}	
      />
    </div>
    );	
}	

export default ScoreSlider;